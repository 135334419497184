import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import { addUserToSession, checkUserInSession } from '../service/sessionService'
import { showToast } from '../component/commonUnit/Toast'
import { setSessionInUser } from '../service/userService'
import Home from './Home'
import "../css/login.scss"
import { generateName } from '../tool/nameGenerator'
import { addStu, addStuToSession, getStuById,registerStudent } from '../service/studentService'
import Cookies from 'js-cookie'
import Loading from '../component/commonUnit/Loading'
import { Button } from "@mui/material"
import { getUserByEmail, updateUser } from "../service/userService"
import { IncrementStuNumInSession } from "../service/sessionService"
import { signInWithEmailAndPassword, validateToken, sendRecoveryEmail } from '../service/authService'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const Join = () => {
  const { id } = useParams()
  const [err, setErr] = useState(false)
  const { currentUser, setCurrentUser } = useContext(AuthContext)
  const [dismiss, setDismiss] = useState(true)
  const [mode, setMode] = useState(0)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const fetchUserData = async () => {
      let userId = Cookies.get('VisPI_userId')
      console.log(userId)
      if (!userId || userId === "undefined") {
        if(dismiss){
          await generateUser()
          return
        }
      }
      else {
        await getStuById(userId).then(async (res) => {
          console.log(res)
          console.log(id)
          console.log(res.session_id)
          if (res && res.session_id == id) {
            setCurrentUser(res)
          } else if(res) {
            res.group_id = null
            res.code_id = null
            if(res.session_list!=null && !res.session_list?.includes(id)){
              res.session_list.push(id)
              await IncrementStuNumInSession(id)
            }
            res.session_id = id
            
            await updateUser(res)
            setCurrentUser(res)
          }
          else{
            await generateUser()
          }
        })
      }

      // window.location.href = '/'
    }

    fetchUserData()
  }, [dismiss])

  const generateUser = async () => {
    const fakeName = generateName()
    let user = {
      first_name: fakeName,
      middle_name: '',
      session_id: id,
      role:3,
      session_list:[id]
    }
    const newUser = await addStu(user)
    console.log(newUser)
    if (newUser !== null) {
      Cookies.set('VisPI_userId', newUser.id)
      setCurrentUser(newUser)
      window.location.href = '/session/' + id
    } else {
      showToast('Fail to join session', 'error')
    }
  }

  const handleRecovery = async (e) => {
    e.preventDefault()
    const email = e.target[0].value
    let result = await sendRecoveryEmail(email)
    if(result.data.result){
      setErr("Sent")
    }
    else{
      setErr("Failed to send")
    }
    console.log(result)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const email = e.target[0].value
    const password = e.target[1].value
    console.log(email)
    try {
      let token = await signInWithEmailAndPassword(email, password)
      console.log(token)
      let user = await validateToken(token)
      console.log(user)
      if (user) {
        console.log(id)
        console.log(user.session_id)
        if(user.session_id != id){
          user.group_id = null
          user.code_id = null
          if(!user.session_list.includes(id)){
            user.session_list.push(id)
            await IncrementStuNumInSession(id)
          }
          user.session_id = id
          
          await updateUser(user)
          
        }
        Cookies.set('VisPI_userId', user.id)
        setCurrentUser(user)
        window.location.href = '/session/' + id
      }
        
    } catch (err) {
      let stu = await getUserByEmail(email)
      if(stu != null){
        setErr("Incorrect Password")
      }
      else{
        setErr("Account with this email does not exist")
      }
      console.log(err)
      if (err.response?.status === 500) {
        console.log(err.response)
      } else {
      }
      console.error(err)
    }
    
    
  }


  const handleRegister = async (e) => {
    e.preventDefault()
    const email = e.target[0].value
    const password = e.target[1].value
    const password2 = e.target[2].value
    console.log(email)
    console.log(password)
    console.log(password2)
    if(password === password2){
      if(password.length<6){
        setErr("Password should be at least 6 characters")
        return
      }
      try {
        const fakeName = generateName()
          let user = {
            first_name: fakeName,
            middle_name: '',
            session_id: id,
            email: email,
            password: password,
            session_list:[id]
          }
          const newUser = await registerStudent(user)
          console.log(newUser)
          if (newUser != null && newUser != "Account with this email already exists") {
            Cookies.set('VisPI_userId', newUser.id)
            setCurrentUser(newUser)
            window.location.href = '/session/' + id
          } else {
            setErr(newUser)
            showToast('Fail to join session', 'error')
          }
          
      } catch (err) {
        console.log(err)
        if (err.response?.status === 500) {
          console.log(err.response)
        } else {
        }
        console.error(err)
      }
    }
    else{
      setErr("Passwords don't match")
    }
    
  }
  const handleRegisterChange = async (e) => {
    e.preventDefault()
    console.log(e)
    e.target = e.target.form
    const email = e.target[0].value
    const password = e.target[1].value
    const password2 = e.target[2].value
    console.log(email)
    console.log(password)
    console.log(password2)
    if(password === password2){
      if(password.length<6){
        setErr("Password should be at least 6 characters")
        return
      }
      else if(!email.includes("@")){
        setErr("Please include an '@' in the email address.")
      }
      else if( err == "Passwords don't match" || err == "Password should be at least 6 characters" || err == "Please include an '@' in the email address."){
        setErr("")
      }
      
    }
    else{
      setErr("Passwords don't match")
    }
    
  }

  const handleRegisterBlur = async (e) => {
    e.preventDefault()
    console.log(e)
    e.target = e.target.form
    const email = e.target[0].value
    const password = e.target[1].value
    const password2 = e.target[2].value
    console.log(email)
    console.log(password)
    console.log(password2)
    let stu = await getUserByEmail(email)
    console.log(stu)
    if(stu != ""){
      
      setErr("Account with this email already exist")
    }
    else if( err == "Account with this email already exist"){
      setErr("")
    }
    
  }

  const handleSubmitChange = async (e) => {
    e.preventDefault()
    e.target = e.target.form
    console.log(e)
    const email = e.target[0].value
    const password = e.target[1].value
    console.log(email.includes("@"))
    if(!email.includes("@")){
      console.log("hello")
      setErr("Please include an '@' in the email address. '")
    }
    else if (err !="Account with this email does not exist"){
      setErr("")
    }
    
    
  }
  const handleSubmitBlur = async (e) => {
    e.preventDefault()
    e.target = e.target.form
    console.log(e)
    const email = e.target[0].value
    const password = e.target[1].value
    console.log(email.includes("@"))
    
    let stu = await getUserByEmail(email)
    console.log(stu)
    if(stu != ""){
      setErr("")
    }
    else{
      setErr("Account with this email does not exist")
    }
    
    
  }

  return currentUser ? <Home /> : (
    <div>
      { mode === 0 && (
        <div className="formContainer">
          <div className="formWrapper">
            <span className="logo">VizPI</span>
            <form className='loginForm'>
              <div style={{display:"block"}} className='loginButton'>
              <Button 
              onClick={()=>setMode(1)}
              >Create Account</Button>
              <p style={{textAlign:"left", marginBottom: "17px"}}>Your account will not be connected to Canvas. Your exercises are not graded and attendance is not recorded. Having an account allows you to revisit your data (e.g., previous codes) at any time. Your account information is encrypted and stored on a school server. </p>

            </div>
            <Button 
            onClick={()=>setMode(2)}
            >Sign In</Button>
            <Button 
            onClick={()=>setDismiss(true)}
            >Continue as Guest</Button>
            <p style={{textAlign:"left"}}>You will have the same experience in this session if you sign in as a guest. However, you won't be able to revisit your data once you exit the site. </p>
            </form>
          </div>
        </div>
      )}
      {mode === 1 && (
        <div className="formContainer">
        <div className="formWrapper">
          <span className="logo">VizPI</span>
          <form onSubmit={handleRegister} onChange={handleRegisterChange} onBlur={handleRegisterBlur} >
            <input type="email" placeholder="email" required />
            <div style={{display:"flex",alignItems:"center"}}>
              <input type={visible?"password":"text"} placeholder="password" required />
              {visible && (
                <VisibilityIcon 
                sx={{paddingBottom:".75em",paddingLeft:".5em"}}
                onClick={()=>setVisible(false)}
                />
              )}
              {!visible && (
                <VisibilityOffIcon 
                sx={{paddingBottom:".75em",paddingLeft:".5em"}}
                onClick={()=>setVisible(true)}
                />
              )}
            </div>
            <div style={{display:"flex",alignItems:"center"}}>
              <input type={visible?"password":"text"} placeholder="re-type password" required />
              {visible && (
                <VisibilityIcon 
                sx={{paddingBottom:".75em",paddingLeft:".5em"}}
                onClick={()=>setVisible(false)}
                />
              )}
              {!visible && (
                <VisibilityOffIcon 
                sx={{paddingBottom:".75em",paddingLeft:".5em"}}
                onClick={()=>setVisible(true)}
                />
              )}
            </div>
            <button type="submit">
              Create Account
            </button>
            <button 
              type="button" 
              onClick={() => setMode(0)}>
              Back
            </button>
            {err && <span>{err}</span>}
          </form>

        </div>
      </div>
      )} 
      {mode === 2 && (
        <div className="formContainer">
        <div className="formWrapper">
          <span className="logo">VizPI</span>
          <form onSubmit={handleSubmit} onChange={handleSubmitChange} onBlur={handleSubmitBlur}>
            <input type="email" placeholder="email" />
            <div style={{display:"flex",alignItems:"center"}}>
              <input type={visible?"password":"text"} placeholder="password" required />
              {visible && (
                <VisibilityIcon 
                sx={{paddingBottom:".75em",paddingLeft:".5em"}}
                onClick={()=>setVisible(false)}
                />
              )}
              {!visible && (
                <VisibilityOffIcon 
                sx={{paddingBottom:".75em",paddingLeft:".5em"}}
                onClick={()=>setVisible(true)}
                />
              )}
            </div>
            <button type="submit" >Sign In</button>
            <button 
              type="button" 
              onClick={() => setMode(0)}>
              Back
            </button>
            <button 
              type="button" 
              onClick={() => setMode(3)}>
              Forgot My Password
            </button>
            {err && <span>{err}</span>}
          </form>
        </div>
      </div>
      )}
      {mode === 3 && (
        <div className="formContainer">
        <div className="formWrapper">
          <span className="logo">VizPI</span>
          <form onSubmit={handleRecovery}>
            <input type="email" placeholder="email" />
            <button type="submit" >Send Recovery Email</button>
            <button 
              type="button" 
              onClick={() => setMode(0)}>
              Back
            </button>
            {err && <span>{err}</span>}
          </form>
        </div>
      </div>
      )} 
    </div>
    
    
  )
}

export default Join
