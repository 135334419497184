import { createContext, useState } from "react";

export const AutoGroupingContext = createContext();

export const AutoGroupingContextProvider = ({ children }) => {
    const [autoGrouping, setAutoGrouping] = useState(false);
    const [autoGroupingRate, setAutoGroupingRate] = useState(33)
    const [regroupingCheck, setRegroupingCheck] = useState(false)
    const [classPassrate, setClassPassrate] = useState(0);

    return (
        <AutoGroupingContext.Provider value={{ autoGrouping, autoGroupingRate, regroupingCheck, classPassrate, setAutoGrouping, setAutoGroupingRate, setRegroupingCheck, setClassPassrate }}>
        {children}
        </AutoGroupingContext.Provider>
    );
}