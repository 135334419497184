const ReviewQuestions = [
  {
    label: "Have you been regrouped?",
    description: "regrouped",
    inputType: "radio",
    options: [
      { label: "Yes", value: "yes" },
      { label: "No", value: "no" }
    ],
  },
  {
    label: "Which group are you more satisfied with?",
    description: "more_satisfied_with",
    inputType: "radio",
    options: [
      { label: "First group", value: "firstGroup" },
      { label: "Second group", value: "secondGroup" },
      { label: "The same", value: "same" },
      { label: "Neither", value: "neither" }
    ],
  },
  {
    label: "Which group would you prefer to work with on future assignments?",
    description: "willing_to_work",
    inputType: "radio",
    options: [
      { label: "First group", value: "firstGroup" },
      { label: "Second group", value: "secondGroup" },
      { label: "The same", value: "same" },
      { label: "Neither", value: "neither" }
    ],
  },
  {
    label: "Tell us more (optional)",
    description: "additional_comment",
    inputType: "textfield",
    options: []
  }];

  const VizmentalReviewQuestions = [{
    label: "How easy was it to interact with the AI assistant?",
    description: "ease_of_interaction",
    inputType: "scale",
    options: [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
      { label: "6", value: 6 },
      { label: "7", value: 7 }
    ],
  },
  {
    label: "How helpful was the AI assistant in solving programming problems?",
    description: "helpfulness",
    inputType: "scale",
    options: [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
      { label: "6", value: 6 },
      { label: "7", value: 7 }
    ],
  },
  {
    label: "How engaged did you feel during the exercises with the AI assistant?",
    description: "engagement",
    inputType: "scale",
    options: [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
      { label: "6", value: 6 },
      { label: "7", value: 7 }
    ],
  },
  {
    label: "Do you feel that using the AI assistant helped you understand the programming concepts better?",
    description: "understanding_improvement",
    inputType: "scale",
    options: [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
      { label: "6", value: 6 },
      { label: "7", value: 7 }
    ],
  },
  {
    label: "How intuitive was the user interface of the tool?",
    description: "ui_intuitiveness",
    inputType: "scale",
    options: [
      { label: "1", value: 1 },
      { label: "2", value: 2 },
      { label: "3", value: 3 },
      { label: "4", value: 4 },
      { label: "5", value: 5 },
      { label: "6", value: 6 },
      { label: "7", value: 7 }
    ],
  },
  {
    label: "Any improvements you want to suggest? (optional)",
    description: "suggestions",
    inputType: "textfield",
    options: []
  }
];

const SphereReviewQuestions = [
  {
    label: "I learned a lot from this session",
    description: "I learned a lot from this session",
    inputType: "emoji",
  },
  {
    label: "Discussions were helpful",
    description: "Discussions were helpful",
    inputType: "emoji",
  },
  {
    label: "I engaged a lot",
    description: "I engaged a lot",
    inputType: "emoji",
  },
  {
    label: "The feedback I received",
    description: "The feedback I received",
    inputType: "subheader",
  },
  {
    label: "Easy to understand",
    description: "The feedback is easy to understand",
    inputType: "emoji",
  },
  {
    label: "Relates to my work",
    description: "The feedback relates to my work",
    inputType: "emoji",
  },
  {
    label: "Helps me critically evaluate my code",
    description: "The feedback helps me critically evaluate my code",
    inputType: "emoji",
  },
  {
    label: "Makes me engage more in the class",
    description: "The feedback makes me engage more in the class",
    inputType: "emoji",
  },
  {
    label: "Can be used even after the class",
    description: "The feedback can be used even after the class",
    inputType: "emoji",
  },

//   {
//   label:"How do you think the instructor's feedback affects you?",
//   description:"feedback_impact",
//   inputType:"multiple_choice",
//   options:[
//     {label:"Increased my understanding", value:"Increased my understanding"},
//     {label:"Made me confused", value:"Made me confused"},
//     {label:"Encouraged me to join the discussion", value:"Encouraged me to join the discussion"},
//     {label:"Discouraged me to join the discussion", value:"Discouraged me to join the discussion"},
//     {label:"Make me engage more in the class", value:"Make me engage more in the class"},
//     {label:"Make me engage less in the class", value:"Make me engage less in the class"},
//     {label:"No impact", value:"No impact"},
//     {label:"I don't know", value:"I don't know"}
//   ]
// },
{
  label: "Tell us more! (optional)",
  description: "additional_comment",
  inputType: "textfield",
  options: []
},

];

export {ReviewQuestions, VizmentalReviewQuestions, SphereReviewQuestions};
